<template>
  <textarea v-if="type === 'textarea'" rows="4"
    class=" w-full p-0 bg-transparent border-none focus:ring-0 placeholder:text-zinc-500" :placeholder="placeholder"
    :value="modelValue" @input="(e) => emits('update:modelValue', e.target.value)" />
  <input v-else class="w-full p-0 bg-transparent border-none focus:ring-0 placeholder:text-zinc-500" :type="type"
    :placeholder="placeholder" :value="modelValue" @input="(e) => emits('update:modelValue', e.target.value)" />
</template>


<script setup>
const emits = defineEmits(['update:modelValue'])
const { type, placeholder, modelValue, label } = defineProps({
  type: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  modelValue: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
})
</script>